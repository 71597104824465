

































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import Pages from "@/core/utils/Pages";
import { HowToVideo } from "@/core/models";
import PagesRender from "@/components/common/PagesRender.vue";
import { VideoPlayer } from "@/components/common";

@Component({ components: { VideoPlayer, PagesRender } })
export default class HowToDash extends Vue {
  @Getter("howTos/loading") loading!: boolean;
  @Getter("howTos/retrieved") retrieved!: boolean;
  @Getter("howTos/getVideos") videos!: HowToVideo[];
  @Action("howTos/getHowTos") getHowTos!: Function;

  limit = 4;
  pages: Pages<HowToVideo> = Pages.Empty();

  @Watch("videos", { immediate: true })
  videosChanged() {
    if (this.videos.length > 0)
      this.pages = new Pages<HowToVideo>(this.limit, this.videos);
  }

  created() {
    if (!this.retrieved) this.getHowTos();
  }
}
